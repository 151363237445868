<template>
    <div class="mr-4 custom-container-box">
        <vs-collapse accordion>
            <vs-collapse-item>
                <div slot="header">
                    <h4 class="custom-header-title">Personalización</h4>
                </div>

                <div class="container mt-4 mb-4">
                    <div class="row">
                        <div class="col-12">
                            <v-select 
                                v-model="columnasSelected"
                                multiple 
                                :closeOnSelect="false"
                                :options="columnas" 
                                class="custom-select"
                                :dir="$vs.rtl ? 'rtl' : 'ltr'"
                            />
                        </div>
                    </div>
                    <div class="row">
                        <!-- <div class="custom-footer col-md-12"></div> -->
                        <div class="col-md-3">
                            <button
                                class="btn btn-second h-12 mt-8"
                                @click="insertUpdateColumns(), $emit( 'colsSelected', columnasSelected )"
                                >Guardar configuración</button>
                        </div>
                    </div>
                </div>
            </vs-collapse-item>
        </vs-collapse>
    </div>
</template>

<script>
import vSelect  from 'vue-select'

export default {
    components: {
        vSelect 
    },
    data() {
		return {
            broker: this.$store.state.AppActiveUser,
            columnas: [
                { label: 'Logo', id: 1 },
                // { label: 'Banco', id: 2 },
                { label: 'Productos', id: 2 }, // cambio por mejora
                { label: 'Mensualidad Inicial', id: 3 },
                { label: 'Plazo', id: 4 },
                { label: 'Pago total', id: 5 },
                { label: 'Tasa', id: 6 },
                { label: 'CAT', id: 7 },
                { label: 'Ingresos Requeridos', id: 8 },
                { label: 'Gastos Iniciales', id: 9 },
                { label: 'Ahorro Mensual', id: 10 },
                { label: 'Ahorro Total', id: 11 },
                { label: 'Promoción', id: 12 },
            ],
            columnasSelected: [
                { label: 'Logo', id: 1 },
                // { label: 'Banco', id: 2 },
                { label: 'Productos', id: 2 }, // cambio por mejora
                { label: 'Mensualidad Inicial', id: 3 },
                { label: 'Plazo', id: 4 },
                { label: 'Pago total', id: 5 },
                { label: 'Tasa', id: 6 },
                { label: 'CAT', id: 7 },
                { label: 'Ingresos Requeridos', id: 8 },
                { label: 'Gastos Iniciales', id: 9 },
                { label: 'Ahorro Mensual', id: 10 },
                { label: 'Ahorro Total', id: 11 },
                { label: 'Promoción', id: 12 },
            ],
            columnasMejora: [],
            columnasSinMejora: [],
            columnasListMejora: [],
            columnasListSinMejora: [],
            isDestinoMejora: false,
            mejoraDestinoId: 0,
        }
    },
    methods: {
        insertUpdateColumns() {
            let self = this;
            let objInsertUpdateColumns = {
                strApiKey: this.broker.ApiKey,
                strMetodo: 'saveColumnasPersonalizadas',
                objCols: {
                    Columnas: JSON.stringify(this.columnasSelected),
                    Broker: this.broker.ApiKey,
                    FiltroCaso: null,
                    Tabla: 1,
                },
            }

            this.$axios.post('/', objInsertUpdateColumns, {
                headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
            }).then( resp => {
                if (resp.data.intEstatus == 0) {
                    this.$vs.notify({
                        title:'Configuración guardada',
                        text:'',
                        color:'success',
                        position:'top-right'
                    });
                } else {
                    this.$vs.notify({
                        title: 'Ocurrio un error al registrar columnas',
                        text: resp.data.strError,
                        color: 'danger',
                        position: 'top-right'
                    });
                }

                // console.log( 'Petición API', resp );
            }).catch(function (error) {
                self.$vs.notify({
                    title: 'Ocurrio un error de sistema',
                    text: error,
                    color: 'danger',
                    position: 'top-right'
                });
            });

            // console.log( objInsertUpdateColumns );
        },
        loadColsJsonByDestino() {

            // Define la lista de columnas a elegir dependiendo del tipo de destino
            if (this.isDestinoMejora) {
                this.columnas = this.columnasListMejora;
            } else {
                this.columnas = this.columnasListSinMejora;
            }

            let self = this;
            // Petición al api para ver si existen columnas personalizadas con el broker
            // Objeto enviado al api
            let objSearchColsFavCompleted = {
                    strApiKey: this.broker.ApiKey,
                    strMetodo: 'getColumnasPersonalizadas',
                    objCols: {
                        Broker: this.broker.ApiKey,
                        FiltroCaso: null,
                        Tabla: 1,
                    },
                }
            // Conexion al API
            this.$axios.post('/', objSearchColsFavCompleted, { 
                headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } 
            }).then(
                resp => {
                    if (resp.data.intEstatus == 0) {
                        // Si la petición es diferente de null y devuelve datos obtengo la data de tabla 2
                        if (resp.data.objContenido && resp.data.objContenido.length > 0) {
                            this.columnasSelected = JSON.parse(resp.data.objContenido[0].Columnas);
                            // Filtro columnas guardadas de la bd si cambia de destino a sin mejora o viceversa
                            if (!this.isDestinoMejora) {
                                this.columnasSelected = this.columnasSelected.filter(item => (item.id != 10 && item.id != 11));
                            }
                            // Emito las columnas obtenidas de bd
                            // console.log( 'Si hay en BD' );
                            this.sendFatherCols();
                        } else {
                            // Reviso si el destino es mejor
                            if (this.isDestinoMejora) {
                                this.columnasSelected = this.columnasMejora;
                            } else {
                                this.columnasSelected = this.columnasSinMejora;
                            }
                            // Emito las columnas por default si no hay datos
                            // console.log( 'No hay en BD' );
                            this.sendFatherCols();
                        }
                    } else {
                        this.$vs.notify({
                        title: 'Ocurrio un error al obtener columnas',
                        text: resp.data.strError,
                        color: 'danger',
                        position: 'top-right'
                        });
                    }
                    // console.log( 'GETDATA', resp.data );
                }
            ).catch(function (error) {
                    self.$vs.notify({
                    title: 'Ocurrio un error de sistema',
                    text: error,
                    color: 'danger',
                    position: 'top-right'
                });
            });
        },
        filtraColumnasByFiltro() {
            this.columnasMejora = [...this.columnasSelected];
            this.columnasSinMejora = [...this.columnasSelected];

            this.columnasListMejora = [...this.columnas],
            this.columnasListSinMejora = [...this.columnas],

            // caso 0
            // Delete: Ahorro Mensual, Ahorro Total
            this.columnasSinMejora = this.columnasMejora.filter(item => (item.id != 10 && item.id != 11));
            this.columnasListSinMejora = this.columnasListSinMejora.filter(item => (item.id != 10 && item.id != 11));
        },
        checkDestinoMejora() {
            if (this.mejoraDestinoId == 19 || 
                this.mejoraDestinoId == 22 || 
                this.mejoraDestinoId == 25 || 
                this.mejoraDestinoId == 33 || 
                this.mejoraDestinoId == 34) {
                this.isDestinoMejora = true;
                // console.log( 'Es mejora' );
            } else {
                this.isDestinoMejora = false;
                // console.log( 'No es mejora' );
            }
        },
        dameCaso() {
            let self = this
            let objRequestDameCaso = {
                strApiKey: this.broker.ApiKey,
                strMetodo: 'DameCaso',
                objCaso: {
                    Id: this.$route.params.id
                }
            }
            this.$axios.post('/', objRequestDameCaso, { 
                headers: { 
                    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } 
            }).then(
                response => {
                    if (response.data.intEstatus == 0) {
                        this.mejoraDestinoId = response.data.objContenido.Destino;
                        this.checkDestinoMejora();
                        this.filtraColumnasByFiltro();
                        this.loadColsJsonByDestino();
                    } else {
                        this.$vs.notify({
                            title: 'Ocurrio un error en DameCaso',
                            text: response.data.strError,
                            color: 'danger',
                            position: 'top-right'
                        });
                    }
                }
            ).catch(function (error) {
                    self.$vs.notify({
                    title: 'Ocurrio un error de sistema',
                    text: error,
                    color: 'danger',
                    position: 'top-right'
                })
            })
        },
        sendFatherCols() {
            this.$emit( 'colsSelected', this.columnasSelected );
        }
    },
    created() {
        this.dameCaso();
    },
}
</script>

<style>
.custom-header-title {
    font-size: 1.1rem;
    font-weight: 500;
}
.custom-container-box .vs-collapse {
    padding-left: 0;
}
.custom-container-box .vs-collapse-item--header {
    background-color: #f8f9fa;
}
.custom-container-box .vs-collapse-item {
    background-color: #fff;
}
.custom-container-box .con-vs-checkbox {
    justify-content: center !important;
}
.custom-select {
    margin-top: -20px;
}
.custom-select ul {
    max-height: 90px;
}
.custom-select ul>li {
    font-size: 1rem;
}
.custom-footer {
    cursor: default;
    height: 120px;
}
</style>